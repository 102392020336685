import { IconHorizontalDots, IconKeyboardArrowLeft, IconKeyboardArrowRight } from '@agentero/icons';
import { styled } from '@agentero/styles/jsx';

import { Text } from './Text';
import { PageType, getPages } from './pagination/getPages';

const PaginationRoot = styled('div', {
	base: {
		display: 'flex',
		height: '2rem',
		justifyContent: 'space-between',
		alignItems: 'center'
	}
});

const PaginationList = styled('ul', {
	base: {
		display: 'flex',
		gap: '4',
		alignItems: 'center'
	}
});

const PaginationItem = styled('li', {
	base: {
		display: 'flex',
		minWidth: '2rem',
		height: '2rem',
		'& svg': {
			width: '100%',
			height: 'auto',
			padding: '0.5rem'
		}
	}
});

const PaginationButton = styled('button', {
	base: {
		position: 'relative',
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontWeight: '600',
		borderRadius: 'sm',
		cursor: 'pointer',
		transition: 'background 0.2s, border 0.2s, color 0.2s',
		textDecoration: 'none',
		textStyle: 'body.small',
		whiteSpace: 'nowrap',
		WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
		minWidth: '2rem',
		height: '2rem',
		paddingInline: '4',
		border: '0.0625rem solid transparent',
		'& svg': {
			width: '2rem',
			height: 'auto',
			padding: '0',
			marginInline: '-8'
		},
		_disabled: {
			pointerEvents: 'none',
			opacity: '0.3'
		},
		_focusVisible: {
			outlineColor: 'focusRing.button.primary',
			outlineOffset: '0.0625rem',
			outlineStyle: 'solid',
			outlineWidth: '0.125rem'
		},
		backgroundColor: 'background.button.ghostEnable',
		color: 'text.button.ghostEnable',
		borderColor: 'background.button.ghostEnable',
		_hover: {
			backgroundColor: 'background.button.ghostHover',
			borderColor: 'background.button.ghostHover'
		},
		'&[aria-selected="true"]': {
			borderColor: 'border.button.secondaryEnable',
			boxShadow: '1'
		}
	}
});

type PaginationProps = {
	onPageChange: (page: number) => void;
	totalCount: number;
	siblingCount?: number;
	currentPage: number;
	pageSize: number;
	className?: string;
};

export const Pagination = ({
	onPageChange,
	totalCount,
	currentPage,
	pageSize
}: PaginationProps) => {
	const pages = getPages({
		currentPage,
		totalCount,
		pageSize
	});

	if (currentPage === 0 || pages.length === 0) {
		return null;
	}

	const onNext = () => {
		onPageChange(currentPage + 1);
	};

	const onPrevious = () => {
		onPageChange(currentPage - 1);
	};

	const listIsVisible = currentPage > 0 && pages.length >= 2;
	const lastPage = pages[pages.length - 1].number;

	return (
		<PaginationRoot>
			<PaginationEntries totalCount={totalCount} currentPage={currentPage} pageSize={pageSize} />
			{listIsVisible && (
				<PaginationList>
					<PaginationItem>
						<PaginationButton onClick={onPrevious} disabled={currentPage === 1}>
							<IconKeyboardArrowLeft />
						</PaginationButton>
					</PaginationItem>
					{pages.map(({ number, type }, index) => (
						<PaginationItem key={index}>
							{type === PageType.Dots ? (
								<IconHorizontalDots />
							) : (
								<PaginationButton
									onClick={() => onPageChange(number)}
									aria-selected={currentPage === number}>
									{number}
								</PaginationButton>
							)}
						</PaginationItem>
					))}
					<PaginationItem>
						<PaginationButton onClick={onNext} disabled={currentPage === lastPage}>
							<IconKeyboardArrowRight />
						</PaginationButton>
					</PaginationItem>
				</PaginationList>
			)}
		</PaginationRoot>
	);
};

const PaginationEntries = ({
	totalCount,
	currentPage,
	pageSize
}: Omit<PaginationProps, 'onPageChange'>) => {
	return (
		<Text size="caption" color="weak">
			Showing {currentPage * pageSize - pageSize + 1} -{' '}
			{currentPage * pageSize > totalCount ? totalCount : currentPage * pageSize} of{' '}
			{Intl.NumberFormat('en-US').format(totalCount)}
		</Text>
	);
};
