// @generated by protoc-gen-es v1.5.1 with parameter "target=ts,import_extension=none"
// @generated from file agentero/portal/rater.proto (package agentero.portal, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { InsuranceType, PolicyComponent } from "../policy/policy_pb";
import { Address, PremiumTerm, Quote } from "../rater/rater_pb";
import { Carrier } from "../carrier/carrier_pb";
import { RaterForm } from "../form/form_pb";
import { Risk } from "../risk/risk_pb";
import { BindRequestStatusCode } from "../binding/binding_pb";

/**
 * @generated from message agentero.portal.GetQuoteLinkRequest
 */
export class GetQuoteLinkRequest extends Message<GetQuoteLinkRequest> {
  /**
   * @generated from field: string quote_id = 1;
   */
  quoteId = "";

  constructor(data?: PartialMessage<GetQuoteLinkRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetQuoteLinkRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "quote_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetQuoteLinkRequest {
    return new GetQuoteLinkRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetQuoteLinkRequest {
    return new GetQuoteLinkRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetQuoteLinkRequest {
    return new GetQuoteLinkRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetQuoteLinkRequest | PlainMessage<GetQuoteLinkRequest> | undefined, b: GetQuoteLinkRequest | PlainMessage<GetQuoteLinkRequest> | undefined): boolean {
    return proto3.util.equals(GetQuoteLinkRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetQuoteLinkResponse
 */
export class GetQuoteLinkResponse extends Message<GetQuoteLinkResponse> {
  /**
   * @generated from field: string quote_link = 1;
   */
  quoteLink = "";

  constructor(data?: PartialMessage<GetQuoteLinkResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetQuoteLinkResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "quote_link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetQuoteLinkResponse {
    return new GetQuoteLinkResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetQuoteLinkResponse {
    return new GetQuoteLinkResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetQuoteLinkResponse {
    return new GetQuoteLinkResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetQuoteLinkResponse | PlainMessage<GetQuoteLinkResponse> | undefined, b: GetQuoteLinkResponse | PlainMessage<GetQuoteLinkResponse> | undefined): boolean {
    return proto3.util.equals(GetQuoteLinkResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.ListRateApplicationsRequest
 */
export class ListRateApplicationsRequest extends Message<ListRateApplicationsRequest> {
  /**
   * @generated from field: agentero.portal.ListRateApplicationsRequest.Filters filters = 1;
   */
  filters?: ListRateApplicationsRequest_Filters;

  /**
   * @generated from field: agentero.portal.ListRateApplicationsRequest.OrderBy order_by = 2;
   */
  orderBy?: ListRateApplicationsRequest_OrderBy;

  /**
   * @generated from field: agentero.portal.ListRateApplicationsRequest.Pagination pagination = 3;
   */
  pagination?: ListRateApplicationsRequest_Pagination;

  constructor(data?: PartialMessage<ListRateApplicationsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.ListRateApplicationsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "filters", kind: "message", T: ListRateApplicationsRequest_Filters },
    { no: 2, name: "order_by", kind: "message", T: ListRateApplicationsRequest_OrderBy },
    { no: 3, name: "pagination", kind: "message", T: ListRateApplicationsRequest_Pagination },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRateApplicationsRequest {
    return new ListRateApplicationsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRateApplicationsRequest {
    return new ListRateApplicationsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRateApplicationsRequest {
    return new ListRateApplicationsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListRateApplicationsRequest | PlainMessage<ListRateApplicationsRequest> | undefined, b: ListRateApplicationsRequest | PlainMessage<ListRateApplicationsRequest> | undefined): boolean {
    return proto3.util.equals(ListRateApplicationsRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.ListRateApplicationsRequest.Filters
 */
export class ListRateApplicationsRequest_Filters extends Message<ListRateApplicationsRequest_Filters> {
  /**
   * @generated from field: string contact_name = 1;
   */
  contactName = "";

  /**
   * @generated from field: repeated agentero.policy.InsuranceType insurance_types = 2;
   */
  insuranceTypes: InsuranceType[] = [];

  constructor(data?: PartialMessage<ListRateApplicationsRequest_Filters>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.ListRateApplicationsRequest.Filters";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "contact_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "insurance_types", kind: "enum", T: proto3.getEnumType(InsuranceType), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRateApplicationsRequest_Filters {
    return new ListRateApplicationsRequest_Filters().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRateApplicationsRequest_Filters {
    return new ListRateApplicationsRequest_Filters().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRateApplicationsRequest_Filters {
    return new ListRateApplicationsRequest_Filters().fromJsonString(jsonString, options);
  }

  static equals(a: ListRateApplicationsRequest_Filters | PlainMessage<ListRateApplicationsRequest_Filters> | undefined, b: ListRateApplicationsRequest_Filters | PlainMessage<ListRateApplicationsRequest_Filters> | undefined): boolean {
    return proto3.util.equals(ListRateApplicationsRequest_Filters, a, b);
  }
}

/**
 * @generated from message agentero.portal.ListRateApplicationsRequest.OrderBy
 */
export class ListRateApplicationsRequest_OrderBy extends Message<ListRateApplicationsRequest_OrderBy> {
  /**
   * @generated from field: agentero.portal.ListRateApplicationsRequest.OrderBy.Field field = 1;
   */
  field = ListRateApplicationsRequest_OrderBy_Field.UNSPECIFIED;

  /**
   * @generated from field: agentero.portal.ListRateApplicationsRequest.OrderBy.Direction direction = 2;
   */
  direction = ListRateApplicationsRequest_OrderBy_Direction.UNSPECIFIED;

  constructor(data?: PartialMessage<ListRateApplicationsRequest_OrderBy>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.ListRateApplicationsRequest.OrderBy";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "field", kind: "enum", T: proto3.getEnumType(ListRateApplicationsRequest_OrderBy_Field) },
    { no: 2, name: "direction", kind: "enum", T: proto3.getEnumType(ListRateApplicationsRequest_OrderBy_Direction) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRateApplicationsRequest_OrderBy {
    return new ListRateApplicationsRequest_OrderBy().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRateApplicationsRequest_OrderBy {
    return new ListRateApplicationsRequest_OrderBy().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRateApplicationsRequest_OrderBy {
    return new ListRateApplicationsRequest_OrderBy().fromJsonString(jsonString, options);
  }

  static equals(a: ListRateApplicationsRequest_OrderBy | PlainMessage<ListRateApplicationsRequest_OrderBy> | undefined, b: ListRateApplicationsRequest_OrderBy | PlainMessage<ListRateApplicationsRequest_OrderBy> | undefined): boolean {
    return proto3.util.equals(ListRateApplicationsRequest_OrderBy, a, b);
  }
}

/**
 * @generated from enum agentero.portal.ListRateApplicationsRequest.OrderBy.Field
 */
export enum ListRateApplicationsRequest_OrderBy_Field {
  /**
   * @generated from enum value: FIELD_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: FIELD_CONTACT_NAME = 1;
   */
  CONTACT_NAME = 1,

  /**
   * @generated from enum value: FIELD_UPDATED_AT = 2;
   */
  UPDATED_AT = 2,

  /**
   * @generated from enum value: FIELD_CREATED_AT = 3;
   */
  CREATED_AT = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(ListRateApplicationsRequest_OrderBy_Field)
proto3.util.setEnumType(ListRateApplicationsRequest_OrderBy_Field, "agentero.portal.ListRateApplicationsRequest.OrderBy.Field", [
  { no: 0, name: "FIELD_UNSPECIFIED" },
  { no: 1, name: "FIELD_CONTACT_NAME" },
  { no: 2, name: "FIELD_UPDATED_AT" },
  { no: 3, name: "FIELD_CREATED_AT" },
]);

/**
 * @generated from enum agentero.portal.ListRateApplicationsRequest.OrderBy.Direction
 */
export enum ListRateApplicationsRequest_OrderBy_Direction {
  /**
   * @generated from enum value: DIRECTION_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: DIRECTION_ASC = 1;
   */
  ASC = 1,

  /**
   * @generated from enum value: DIRECTION_DESC = 2;
   */
  DESC = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(ListRateApplicationsRequest_OrderBy_Direction)
proto3.util.setEnumType(ListRateApplicationsRequest_OrderBy_Direction, "agentero.portal.ListRateApplicationsRequest.OrderBy.Direction", [
  { no: 0, name: "DIRECTION_UNSPECIFIED" },
  { no: 1, name: "DIRECTION_ASC" },
  { no: 2, name: "DIRECTION_DESC" },
]);

/**
 * @generated from message agentero.portal.ListRateApplicationsRequest.Pagination
 */
export class ListRateApplicationsRequest_Pagination extends Message<ListRateApplicationsRequest_Pagination> {
  /**
   * @generated from field: int64 offset = 1;
   */
  offset = protoInt64.zero;

  /**
   * @generated from field: int64 limit = 2;
   */
  limit = protoInt64.zero;

  constructor(data?: PartialMessage<ListRateApplicationsRequest_Pagination>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.ListRateApplicationsRequest.Pagination";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "limit", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRateApplicationsRequest_Pagination {
    return new ListRateApplicationsRequest_Pagination().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRateApplicationsRequest_Pagination {
    return new ListRateApplicationsRequest_Pagination().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRateApplicationsRequest_Pagination {
    return new ListRateApplicationsRequest_Pagination().fromJsonString(jsonString, options);
  }

  static equals(a: ListRateApplicationsRequest_Pagination | PlainMessage<ListRateApplicationsRequest_Pagination> | undefined, b: ListRateApplicationsRequest_Pagination | PlainMessage<ListRateApplicationsRequest_Pagination> | undefined): boolean {
    return proto3.util.equals(ListRateApplicationsRequest_Pagination, a, b);
  }
}

/**
 * @generated from message agentero.portal.ListRateApplicationsResponse
 */
export class ListRateApplicationsResponse extends Message<ListRateApplicationsResponse> {
  /**
   * @generated from field: int64 total_count = 1;
   */
  totalCount = protoInt64.zero;

  /**
   * @generated from field: repeated agentero.portal.RateApplication rate_applications = 2;
   */
  rateApplications: RateApplication[] = [];

  constructor(data?: PartialMessage<ListRateApplicationsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.ListRateApplicationsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "total_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "rate_applications", kind: "message", T: RateApplication, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRateApplicationsResponse {
    return new ListRateApplicationsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRateApplicationsResponse {
    return new ListRateApplicationsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRateApplicationsResponse {
    return new ListRateApplicationsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListRateApplicationsResponse | PlainMessage<ListRateApplicationsResponse> | undefined, b: ListRateApplicationsResponse | PlainMessage<ListRateApplicationsResponse> | undefined): boolean {
    return proto3.util.equals(ListRateApplicationsResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.RateApplication
 */
export class RateApplication extends Message<RateApplication> {
  /**
   * @generated from field: string rate_application_id = 1;
   */
  rateApplicationId = "";

  /**
   * @generated from field: string rate_id = 2;
   */
  rateId = "";

  /**
   * @generated from field: string first_name = 3;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 4;
   */
  lastName = "";

  /**
   * @generated from field: agentero.rater.Address address = 5;
   */
  address?: Address;

  /**
   * @generated from field: agentero.policy.InsuranceType insurance_type = 6;
   */
  insuranceType = InsuranceType.UNDEFINED_INSURANCE_TYPE;

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 7;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 8;
   */
  updatedAt?: Timestamp;

  /**
   * @generated from field: repeated agentero.portal.RateApplication.Carrier carriers = 9;
   */
  carriers: RateApplication_Carrier[] = [];

  constructor(data?: PartialMessage<RateApplication>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.RateApplication";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "rate_application_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "rate_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "address", kind: "message", T: Address },
    { no: 6, name: "insurance_type", kind: "enum", T: proto3.getEnumType(InsuranceType) },
    { no: 7, name: "created_at", kind: "message", T: Timestamp },
    { no: 8, name: "updated_at", kind: "message", T: Timestamp },
    { no: 9, name: "carriers", kind: "message", T: RateApplication_Carrier, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RateApplication {
    return new RateApplication().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RateApplication {
    return new RateApplication().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RateApplication {
    return new RateApplication().fromJsonString(jsonString, options);
  }

  static equals(a: RateApplication | PlainMessage<RateApplication> | undefined, b: RateApplication | PlainMessage<RateApplication> | undefined): boolean {
    return proto3.util.equals(RateApplication, a, b);
  }
}

/**
 * @generated from message agentero.portal.RateApplication.Carrier
 */
export class RateApplication_Carrier extends Message<RateApplication_Carrier> {
  /**
   * @generated from field: agentero.carrier.Carrier carrier = 1;
   */
  carrier = Carrier.UNDEFINED;

  /**
   * @generated from field: agentero.portal.RateApplication.Carrier.Status status = 2;
   */
  status = RateApplication_Carrier_Status.UNSPECIFIED;

  constructor(data?: PartialMessage<RateApplication_Carrier>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.RateApplication.Carrier";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "carrier", kind: "enum", T: proto3.getEnumType(Carrier) },
    { no: 2, name: "status", kind: "enum", T: proto3.getEnumType(RateApplication_Carrier_Status) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RateApplication_Carrier {
    return new RateApplication_Carrier().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RateApplication_Carrier {
    return new RateApplication_Carrier().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RateApplication_Carrier {
    return new RateApplication_Carrier().fromJsonString(jsonString, options);
  }

  static equals(a: RateApplication_Carrier | PlainMessage<RateApplication_Carrier> | undefined, b: RateApplication_Carrier | PlainMessage<RateApplication_Carrier> | undefined): boolean {
    return proto3.util.equals(RateApplication_Carrier, a, b);
  }
}

/**
 * @generated from enum agentero.portal.RateApplication.Carrier.Status
 */
export enum RateApplication_Carrier_Status {
  /**
   * @generated from enum value: STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: STATUS_ERROR = 1;
   */
  ERROR = 1,

  /**
   * @generated from enum value: STATUS_SUCCESS = 2;
   */
  SUCCESS = 2,

  /**
   * @generated from enum value: STATUS_BIND_REQUESTED = 3;
   */
  BIND_REQUESTED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(RateApplication_Carrier_Status)
proto3.util.setEnumType(RateApplication_Carrier_Status, "agentero.portal.RateApplication.Carrier.Status", [
  { no: 0, name: "STATUS_UNSPECIFIED" },
  { no: 1, name: "STATUS_ERROR" },
  { no: 2, name: "STATUS_SUCCESS" },
  { no: 3, name: "STATUS_BIND_REQUESTED" },
]);

/**
 * @generated from message agentero.portal.GetRateDataRequest
 */
export class GetRateDataRequest extends Message<GetRateDataRequest> {
  /**
   * @generated from field: string rate_id = 1;
   */
  rateId = "";

  constructor(data?: PartialMessage<GetRateDataRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetRateDataRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "rate_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRateDataRequest {
    return new GetRateDataRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRateDataRequest {
    return new GetRateDataRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRateDataRequest {
    return new GetRateDataRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetRateDataRequest | PlainMessage<GetRateDataRequest> | undefined, b: GetRateDataRequest | PlainMessage<GetRateDataRequest> | undefined): boolean {
    return proto3.util.equals(GetRateDataRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetRateDataResponse
 */
export class GetRateDataResponse extends Message<GetRateDataResponse> {
  /**
   * @generated from field: map<string, string> rate_data = 1;
   */
  rateData: { [key: string]: string } = {};

  /**
   * @generated from field: agentero.rater.Address address = 2;
   */
  address?: Address;

  constructor(data?: PartialMessage<GetRateDataResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetRateDataResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "rate_data", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 2, name: "address", kind: "message", T: Address },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRateDataResponse {
    return new GetRateDataResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRateDataResponse {
    return new GetRateDataResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRateDataResponse {
    return new GetRateDataResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetRateDataResponse | PlainMessage<GetRateDataResponse> | undefined, b: GetRateDataResponse | PlainMessage<GetRateDataResponse> | undefined): boolean {
    return proto3.util.equals(GetRateDataResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.RateRequest
 */
export class RateRequest extends Message<RateRequest> {
  /**
   * @generated from field: agentero.rater.Address address = 1;
   */
  address?: Address;

  /**
   * @generated from field: agentero.policy.InsuranceType insurance_type = 2;
   */
  insuranceType = InsuranceType.UNDEFINED_INSURANCE_TYPE;

  /**
   * RateData is the data provided to quote.
   *
   * @generated from field: map<string, string> rate_data = 3;
   */
  rateData: { [key: string]: string } = {};

  /**
   * @generated from field: string rate_id = 5;
   */
  rateId = "";

  /**
   * @generated from field: string lead_risk_id = 6;
   */
  leadRiskId = "";

  constructor(data?: PartialMessage<RateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.RateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "address", kind: "message", T: Address },
    { no: 2, name: "insurance_type", kind: "enum", T: proto3.getEnumType(InsuranceType) },
    { no: 3, name: "rate_data", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 5, name: "rate_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "lead_risk_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RateRequest {
    return new RateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RateRequest {
    return new RateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RateRequest {
    return new RateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RateRequest | PlainMessage<RateRequest> | undefined, b: RateRequest | PlainMessage<RateRequest> | undefined): boolean {
    return proto3.util.equals(RateRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetCarrierApplicationsStatusRequest
 */
export class GetCarrierApplicationsStatusRequest extends Message<GetCarrierApplicationsStatusRequest> {
  /**
   * @generated from field: string address_state = 1;
   */
  addressState = "";

  /**
   * @generated from field: agentero.policy.InsuranceType insurance_type = 2;
   */
  insuranceType = InsuranceType.UNDEFINED_INSURANCE_TYPE;

  /**
   * @generated from field: map<string, agentero.portal.DataValue> rate_data = 3;
   */
  rateData: { [key: string]: DataValue } = {};

  /**
   * @generated from field: string address_zip_code = 4;
   */
  addressZipCode = "";

  constructor(data?: PartialMessage<GetCarrierApplicationsStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetCarrierApplicationsStatusRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "address_state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "insurance_type", kind: "enum", T: proto3.getEnumType(InsuranceType) },
    { no: 3, name: "rate_data", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: DataValue} },
    { no: 4, name: "address_zip_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCarrierApplicationsStatusRequest {
    return new GetCarrierApplicationsStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCarrierApplicationsStatusRequest {
    return new GetCarrierApplicationsStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCarrierApplicationsStatusRequest {
    return new GetCarrierApplicationsStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetCarrierApplicationsStatusRequest | PlainMessage<GetCarrierApplicationsStatusRequest> | undefined, b: GetCarrierApplicationsStatusRequest | PlainMessage<GetCarrierApplicationsStatusRequest> | undefined): boolean {
    return proto3.util.equals(GetCarrierApplicationsStatusRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.DataValue
 */
export class DataValue extends Message<DataValue> {
  /**
   * @generated from field: optional string value = 1;
   */
  value?: string;

  constructor(data?: PartialMessage<DataValue>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.DataValue";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DataValue {
    return new DataValue().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DataValue {
    return new DataValue().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DataValue {
    return new DataValue().fromJsonString(jsonString, options);
  }

  static equals(a: DataValue | PlainMessage<DataValue> | undefined, b: DataValue | PlainMessage<DataValue> | undefined): boolean {
    return proto3.util.equals(DataValue, a, b);
  }
}

/**
 * @generated from message agentero.portal.DateYear
 */
export class DateYear extends Message<DateYear> {
  /**
   * @generated from field: int32 value = 1;
   */
  value = 0;

  constructor(data?: PartialMessage<DateYear>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.DateYear";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DateYear {
    return new DateYear().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DateYear {
    return new DateYear().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DateYear {
    return new DateYear().fromJsonString(jsonString, options);
  }

  static equals(a: DateYear | PlainMessage<DateYear> | undefined, b: DateYear | PlainMessage<DateYear> | undefined): boolean {
    return proto3.util.equals(DateYear, a, b);
  }
}

/**
 * @generated from message agentero.portal.Email
 */
export class Email extends Message<Email> {
  /**
   * @generated from field: string value = 1;
   */
  value = "";

  constructor(data?: PartialMessage<Email>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.Email";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Email {
    return new Email().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Email {
    return new Email().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Email {
    return new Email().fromJsonString(jsonString, options);
  }

  static equals(a: Email | PlainMessage<Email> | undefined, b: Email | PlainMessage<Email> | undefined): boolean {
    return proto3.util.equals(Email, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetRaterSchemaRequest
 */
export class GetRaterSchemaRequest extends Message<GetRaterSchemaRequest> {
  /**
   * @generated from field: agentero.policy.InsuranceType insurance_type = 1;
   */
  insuranceType = InsuranceType.UNDEFINED_INSURANCE_TYPE;

  /**
   * @generated from field: string state = 2;
   */
  state = "";

  constructor(data?: PartialMessage<GetRaterSchemaRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetRaterSchemaRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "insurance_type", kind: "enum", T: proto3.getEnumType(InsuranceType) },
    { no: 2, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRaterSchemaRequest {
    return new GetRaterSchemaRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRaterSchemaRequest {
    return new GetRaterSchemaRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRaterSchemaRequest {
    return new GetRaterSchemaRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetRaterSchemaRequest | PlainMessage<GetRaterSchemaRequest> | undefined, b: GetRaterSchemaRequest | PlainMessage<GetRaterSchemaRequest> | undefined): boolean {
    return proto3.util.equals(GetRaterSchemaRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetRaterSchemaResponse
 */
export class GetRaterSchemaResponse extends Message<GetRaterSchemaResponse> {
  /**
   * @generated from field: agentero.form.RaterForm rater_form = 1;
   */
  raterForm?: RaterForm;

  constructor(data?: PartialMessage<GetRaterSchemaResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetRaterSchemaResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "rater_form", kind: "message", T: RaterForm },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRaterSchemaResponse {
    return new GetRaterSchemaResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRaterSchemaResponse {
    return new GetRaterSchemaResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRaterSchemaResponse {
    return new GetRaterSchemaResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetRaterSchemaResponse | PlainMessage<GetRaterSchemaResponse> | undefined, b: GetRaterSchemaResponse | PlainMessage<GetRaterSchemaResponse> | undefined): boolean {
    return proto3.util.equals(GetRaterSchemaResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.StreamingQuoteResponse
 */
export class StreamingQuoteResponse extends Message<StreamingQuoteResponse> {
  /**
   * the response will in this order:
   * 1. return generated rate id
   * 2. return a quote at a time, for each of the carriers returned in the previous step
   * 2.5 in case of a quote error, will return error message for that carrier instead of quote
   *
   * @generated from oneof agentero.portal.StreamingQuoteResponse.response
   */
  response: {
    /**
     * @generated from field: agentero.rater.Quote quote = 1;
     */
    value: Quote;
    case: "quote";
  } | {
    /**
     * @generated from field: agentero.portal.ErrorResponse error = 2;
     */
    value: ErrorResponse;
    case: "error";
  } | {
    /**
     * @generated from field: agentero.portal.Rate rate = 3;
     */
    value: Rate;
    case: "rate";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<StreamingQuoteResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.StreamingQuoteResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "quote", kind: "message", T: Quote, oneof: "response" },
    { no: 2, name: "error", kind: "message", T: ErrorResponse, oneof: "response" },
    { no: 3, name: "rate", kind: "message", T: Rate, oneof: "response" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StreamingQuoteResponse {
    return new StreamingQuoteResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StreamingQuoteResponse {
    return new StreamingQuoteResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StreamingQuoteResponse {
    return new StreamingQuoteResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StreamingQuoteResponse | PlainMessage<StreamingQuoteResponse> | undefined, b: StreamingQuoteResponse | PlainMessage<StreamingQuoteResponse> | undefined): boolean {
    return proto3.util.equals(StreamingQuoteResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.Rate
 */
export class Rate extends Message<Rate> {
  /**
   * @generated from field: string rate_id = 1;
   */
  rateId = "";

  /**
   * @generated from field: string application_id = 2;
   */
  applicationId = "";

  constructor(data?: PartialMessage<Rate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.Rate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "rate_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "application_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Rate {
    return new Rate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Rate {
    return new Rate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Rate {
    return new Rate().fromJsonString(jsonString, options);
  }

  static equals(a: Rate | PlainMessage<Rate> | undefined, b: Rate | PlainMessage<Rate> | undefined): boolean {
    return proto3.util.equals(Rate, a, b);
  }
}

/**
 * @generated from message agentero.portal.ErrorResponse
 */
export class ErrorResponse extends Message<ErrorResponse> {
  /**
   * @generated from field: agentero.portal.ErrorResponse.ErrorStatus status = 1;
   */
  status = ErrorResponse_ErrorStatus.FAILED;

  /**
   * @generated from oneof agentero.portal.ErrorResponse.error_value
   */
  errorValue: {
    /**
     * in case of a "failed" error, this string will
     * contain the error message from from our backend.
     *
     * @generated from field: string failed_error = 2;
     */
    value: string;
    case: "failedError";
  } | {
    /**
     * @generated from field: agentero.portal.ErrorResponse.RejectionError rejection_error = 3;
     */
    value: ErrorResponse_RejectionError;
    case: "rejectionError";
  } | {
    /**
     * @generated from field: string non_quoted_error = 5;
     */
    value: string;
    case: "nonQuotedError";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: agentero.carrier.Carrier carrier = 4;
   */
  carrier = Carrier.UNDEFINED;

  /**
   * @generated from field: bool is_binding_with_agentero = 6;
   */
  isBindingWithAgentero = false;

  constructor(data?: PartialMessage<ErrorResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.ErrorResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(ErrorResponse_ErrorStatus) },
    { no: 2, name: "failed_error", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "error_value" },
    { no: 3, name: "rejection_error", kind: "message", T: ErrorResponse_RejectionError, oneof: "error_value" },
    { no: 5, name: "non_quoted_error", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "error_value" },
    { no: 4, name: "carrier", kind: "enum", T: proto3.getEnumType(Carrier) },
    { no: 6, name: "is_binding_with_agentero", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ErrorResponse {
    return new ErrorResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ErrorResponse {
    return new ErrorResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ErrorResponse {
    return new ErrorResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ErrorResponse | PlainMessage<ErrorResponse> | undefined, b: ErrorResponse | PlainMessage<ErrorResponse> | undefined): boolean {
    return proto3.util.equals(ErrorResponse, a, b);
  }
}

/**
 * FAILED represents an internal error,
 * it might be the carrier API is down,
 * we have a bug in our system, etc.
 * REJECTED represents a quote has been
 * rejected by the carrier. It will contain
 * a reason, for example the home has wooden roof.
 *
 * @generated from enum agentero.portal.ErrorResponse.ErrorStatus
 */
export enum ErrorResponse_ErrorStatus {
  /**
   * @generated from enum value: FAILED = 0;
   */
  FAILED = 0,

  /**
   * @generated from enum value: REJECTED = 1;
   */
  REJECTED = 1,

  /**
   * @generated from enum value: NON_QUOTED = 2;
   */
  NON_QUOTED = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(ErrorResponse_ErrorStatus)
proto3.util.setEnumType(ErrorResponse_ErrorStatus, "agentero.portal.ErrorResponse.ErrorStatus", [
  { no: 0, name: "FAILED" },
  { no: 1, name: "REJECTED" },
  { no: 2, name: "NON_QUOTED" },
]);

/**
 * RejectionError should contain the code
 * and text directly from the carrier API.
 * EX: https://staging.portal.ourbranch.com/errors-codes
 *
 * @generated from message agentero.portal.ErrorResponse.RejectionError
 */
export class ErrorResponse_RejectionError extends Message<ErrorResponse_RejectionError> {
  /**
   * @generated from field: string code = 1;
   */
  code = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  constructor(data?: PartialMessage<ErrorResponse_RejectionError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.ErrorResponse.RejectionError";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ErrorResponse_RejectionError {
    return new ErrorResponse_RejectionError().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ErrorResponse_RejectionError {
    return new ErrorResponse_RejectionError().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ErrorResponse_RejectionError {
    return new ErrorResponse_RejectionError().fromJsonString(jsonString, options);
  }

  static equals(a: ErrorResponse_RejectionError | PlainMessage<ErrorResponse_RejectionError> | undefined, b: ErrorResponse_RejectionError | PlainMessage<ErrorResponse_RejectionError> | undefined): boolean {
    return proto3.util.equals(ErrorResponse_RejectionError, a, b);
  }
}

/**
 * @generated from message agentero.portal.LookUpRequest
 */
export class LookUpRequest extends Message<LookUpRequest> {
  /**
   * @generated from field: agentero.risk.Risk risk = 1;
   */
  risk?: Risk;

  constructor(data?: PartialMessage<LookUpRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.LookUpRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "risk", kind: "message", T: Risk },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LookUpRequest {
    return new LookUpRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LookUpRequest {
    return new LookUpRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LookUpRequest {
    return new LookUpRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LookUpRequest | PlainMessage<LookUpRequest> | undefined, b: LookUpRequest | PlainMessage<LookUpRequest> | undefined): boolean {
    return proto3.util.equals(LookUpRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.LookUpResponse
 */
export class LookUpResponse extends Message<LookUpResponse> {
  /**
   * @generated from field: agentero.risk.Risk risk = 1;
   */
  risk?: Risk;

  constructor(data?: PartialMessage<LookUpResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.LookUpResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "risk", kind: "message", T: Risk },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LookUpResponse {
    return new LookUpResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LookUpResponse {
    return new LookUpResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LookUpResponse {
    return new LookUpResponse().fromJsonString(jsonString, options);
  }

  static equals(a: LookUpResponse | PlainMessage<LookUpResponse> | undefined, b: LookUpResponse | PlainMessage<LookUpResponse> | undefined): boolean {
    return proto3.util.equals(LookUpResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.PrefillRiskResponse
 */
export class PrefillRiskResponse extends Message<PrefillRiskResponse> {
  /**
   * @generated from field: map<string, string> rate_data = 3;
   */
  rateData: { [key: string]: string } = {};

  constructor(data?: PartialMessage<PrefillRiskResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.PrefillRiskResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: "rate_data", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PrefillRiskResponse {
    return new PrefillRiskResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PrefillRiskResponse {
    return new PrefillRiskResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PrefillRiskResponse {
    return new PrefillRiskResponse().fromJsonString(jsonString, options);
  }

  static equals(a: PrefillRiskResponse | PlainMessage<PrefillRiskResponse> | undefined, b: PrefillRiskResponse | PlainMessage<PrefillRiskResponse> | undefined): boolean {
    return proto3.util.equals(PrefillRiskResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetSemseeRaterURLRequest
 */
export class GetSemseeRaterURLRequest extends Message<GetSemseeRaterURLRequest> {
  constructor(data?: PartialMessage<GetSemseeRaterURLRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetSemseeRaterURLRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSemseeRaterURLRequest {
    return new GetSemseeRaterURLRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSemseeRaterURLRequest {
    return new GetSemseeRaterURLRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSemseeRaterURLRequest {
    return new GetSemseeRaterURLRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetSemseeRaterURLRequest | PlainMessage<GetSemseeRaterURLRequest> | undefined, b: GetSemseeRaterURLRequest | PlainMessage<GetSemseeRaterURLRequest> | undefined): boolean {
    return proto3.util.equals(GetSemseeRaterURLRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetSemseeRaterURLResponse
 */
export class GetSemseeRaterURLResponse extends Message<GetSemseeRaterURLResponse> {
  /**
   * @generated from field: string create_quotes_url = 1 [deprecated = true];
   * @deprecated
   */
  createQuotesUrl = "";

  /**
   * @generated from field: string list_quotes_url = 2 [deprecated = true];
   * @deprecated
   */
  listQuotesUrl = "";

  /**
   * @generated from oneof agentero.portal.GetSemseeRaterURLResponse.response
   */
  response: {
    /**
     * @generated from field: agentero.portal.GetSemseeRaterURLResponse.Links links = 3;
     */
    value: GetSemseeRaterURLResponse_Links;
    case: "links";
  } | {
    /**
     * @generated from field: agentero.portal.GetSemseeRaterURLResponse.RejectionError rejection_error = 4;
     */
    value: GetSemseeRaterURLResponse_RejectionError;
    case: "rejectionError";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<GetSemseeRaterURLResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetSemseeRaterURLResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "create_quotes_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "list_quotes_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "links", kind: "message", T: GetSemseeRaterURLResponse_Links, oneof: "response" },
    { no: 4, name: "rejection_error", kind: "message", T: GetSemseeRaterURLResponse_RejectionError, oneof: "response" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSemseeRaterURLResponse {
    return new GetSemseeRaterURLResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSemseeRaterURLResponse {
    return new GetSemseeRaterURLResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSemseeRaterURLResponse {
    return new GetSemseeRaterURLResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetSemseeRaterURLResponse | PlainMessage<GetSemseeRaterURLResponse> | undefined, b: GetSemseeRaterURLResponse | PlainMessage<GetSemseeRaterURLResponse> | undefined): boolean {
    return proto3.util.equals(GetSemseeRaterURLResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetSemseeRaterURLResponse.RejectionError
 */
export class GetSemseeRaterURLResponse_RejectionError extends Message<GetSemseeRaterURLResponse_RejectionError> {
  /**
   * @generated from field: string description = 1;
   */
  description = "";

  constructor(data?: PartialMessage<GetSemseeRaterURLResponse_RejectionError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetSemseeRaterURLResponse.RejectionError";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSemseeRaterURLResponse_RejectionError {
    return new GetSemseeRaterURLResponse_RejectionError().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSemseeRaterURLResponse_RejectionError {
    return new GetSemseeRaterURLResponse_RejectionError().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSemseeRaterURLResponse_RejectionError {
    return new GetSemseeRaterURLResponse_RejectionError().fromJsonString(jsonString, options);
  }

  static equals(a: GetSemseeRaterURLResponse_RejectionError | PlainMessage<GetSemseeRaterURLResponse_RejectionError> | undefined, b: GetSemseeRaterURLResponse_RejectionError | PlainMessage<GetSemseeRaterURLResponse_RejectionError> | undefined): boolean {
    return proto3.util.equals(GetSemseeRaterURLResponse_RejectionError, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetSemseeRaterURLResponse.Links
 */
export class GetSemseeRaterURLResponse_Links extends Message<GetSemseeRaterURLResponse_Links> {
  /**
   * @generated from field: string create_quotes_url = 1;
   */
  createQuotesUrl = "";

  /**
   * @generated from field: string list_quotes_url = 2;
   */
  listQuotesUrl = "";

  constructor(data?: PartialMessage<GetSemseeRaterURLResponse_Links>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetSemseeRaterURLResponse.Links";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "create_quotes_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "list_quotes_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSemseeRaterURLResponse_Links {
    return new GetSemseeRaterURLResponse_Links().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSemseeRaterURLResponse_Links {
    return new GetSemseeRaterURLResponse_Links().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSemseeRaterURLResponse_Links {
    return new GetSemseeRaterURLResponse_Links().fromJsonString(jsonString, options);
  }

  static equals(a: GetSemseeRaterURLResponse_Links | PlainMessage<GetSemseeRaterURLResponse_Links> | undefined, b: GetSemseeRaterURLResponse_Links | PlainMessage<GetSemseeRaterURLResponse_Links> | undefined): boolean {
    return proto3.util.equals(GetSemseeRaterURLResponse_Links, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetRateResultsByRateIDRequest
 */
export class GetRateResultsByRateIDRequest extends Message<GetRateResultsByRateIDRequest> {
  /**
   * @generated from field: string rate_id = 1;
   */
  rateId = "";

  constructor(data?: PartialMessage<GetRateResultsByRateIDRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetRateResultsByRateIDRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "rate_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRateResultsByRateIDRequest {
    return new GetRateResultsByRateIDRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRateResultsByRateIDRequest {
    return new GetRateResultsByRateIDRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRateResultsByRateIDRequest {
    return new GetRateResultsByRateIDRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetRateResultsByRateIDRequest | PlainMessage<GetRateResultsByRateIDRequest> | undefined, b: GetRateResultsByRateIDRequest | PlainMessage<GetRateResultsByRateIDRequest> | undefined): boolean {
    return proto3.util.equals(GetRateResultsByRateIDRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetRateResultsByRateIDResponse
 */
export class GetRateResultsByRateIDResponse extends Message<GetRateResultsByRateIDResponse> {
  /**
   * @generated from field: repeated agentero.portal.RateResult results = 1;
   */
  results: RateResult[] = [];

  /**
   * @generated from field: string rate_id = 2;
   */
  rateId = "";

  /**
   * @generated from field: string application_id = 3;
   */
  applicationId = "";

  /**
   * @generated from field: agentero.policy.InsuranceType insurance_type = 4;
   */
  insuranceType = InsuranceType.UNDEFINED_INSURANCE_TYPE;

  constructor(data?: PartialMessage<GetRateResultsByRateIDResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetRateResultsByRateIDResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "results", kind: "message", T: RateResult, repeated: true },
    { no: 2, name: "rate_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "application_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "insurance_type", kind: "enum", T: proto3.getEnumType(InsuranceType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRateResultsByRateIDResponse {
    return new GetRateResultsByRateIDResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRateResultsByRateIDResponse {
    return new GetRateResultsByRateIDResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRateResultsByRateIDResponse {
    return new GetRateResultsByRateIDResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetRateResultsByRateIDResponse | PlainMessage<GetRateResultsByRateIDResponse> | undefined, b: GetRateResultsByRateIDResponse | PlainMessage<GetRateResultsByRateIDResponse> | undefined): boolean {
    return proto3.util.equals(GetRateResultsByRateIDResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.RateResult
 */
export class RateResult extends Message<RateResult> {
  /**
   * @generated from oneof agentero.portal.RateResult.result
   */
  result: {
    /**
     * @generated from field: agentero.rater.Quote quote = 1;
     */
    value: Quote;
    case: "quote";
  } | {
    /**
     * @generated from field: agentero.portal.ErrorResponse error = 2;
     */
    value: ErrorResponse;
    case: "error";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<RateResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.RateResult";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "quote", kind: "message", T: Quote, oneof: "result" },
    { no: 2, name: "error", kind: "message", T: ErrorResponse, oneof: "result" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RateResult {
    return new RateResult().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RateResult {
    return new RateResult().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RateResult {
    return new RateResult().fromJsonString(jsonString, options);
  }

  static equals(a: RateResult | PlainMessage<RateResult> | undefined, b: RateResult | PlainMessage<RateResult> | undefined): boolean {
    return proto3.util.equals(RateResult, a, b);
  }
}

/**
 * @generated from message agentero.portal.ApplicationDetailsResult
 */
export class ApplicationDetailsResult extends Message<ApplicationDetailsResult> {
  /**
   * @generated from field: agentero.portal.RateResult rate_result = 1;
   */
  rateResult?: RateResult;

  /**
   * @generated from field: double final_premium = 2;
   */
  finalPremium = 0;

  /**
   * @generated from field: optional agentero.rater.PremiumTerm premium_term = 3;
   */
  premiumTerm?: PremiumTerm;

  /**
   * @generated from field: repeated agentero.policy.PolicyComponent components = 4;
   */
  components: PolicyComponent[] = [];

  /**
   * @generated from field: optional agentero.binding.BindRequestStatusCode status = 5;
   */
  status?: BindRequestStatusCode;

  /**
   * @generated from field: optional bool action_required = 6;
   */
  actionRequired?: boolean;

  constructor(data?: PartialMessage<ApplicationDetailsResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.ApplicationDetailsResult";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "rate_result", kind: "message", T: RateResult },
    { no: 2, name: "final_premium", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "premium_term", kind: "enum", T: proto3.getEnumType(PremiumTerm), opt: true },
    { no: 4, name: "components", kind: "message", T: PolicyComponent, repeated: true },
    { no: 5, name: "status", kind: "enum", T: proto3.getEnumType(BindRequestStatusCode), opt: true },
    { no: 6, name: "action_required", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ApplicationDetailsResult {
    return new ApplicationDetailsResult().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ApplicationDetailsResult {
    return new ApplicationDetailsResult().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ApplicationDetailsResult {
    return new ApplicationDetailsResult().fromJsonString(jsonString, options);
  }

  static equals(a: ApplicationDetailsResult | PlainMessage<ApplicationDetailsResult> | undefined, b: ApplicationDetailsResult | PlainMessage<ApplicationDetailsResult> | undefined): boolean {
    return proto3.util.equals(ApplicationDetailsResult, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetApplicationDetailsRequest
 */
export class GetApplicationDetailsRequest extends Message<GetApplicationDetailsRequest> {
  /**
   * @generated from field: string application_id = 1;
   */
  applicationId = "";

  constructor(data?: PartialMessage<GetApplicationDetailsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetApplicationDetailsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "application_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetApplicationDetailsRequest {
    return new GetApplicationDetailsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetApplicationDetailsRequest {
    return new GetApplicationDetailsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetApplicationDetailsRequest {
    return new GetApplicationDetailsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetApplicationDetailsRequest | PlainMessage<GetApplicationDetailsRequest> | undefined, b: GetApplicationDetailsRequest | PlainMessage<GetApplicationDetailsRequest> | undefined): boolean {
    return proto3.util.equals(GetApplicationDetailsRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetApplicationDetailsResponse
 */
export class GetApplicationDetailsResponse extends Message<GetApplicationDetailsResponse> {
  /**
   * @generated from field: repeated agentero.portal.ApplicationDetailsResult results = 1;
   */
  results: ApplicationDetailsResult[] = [];

  /**
   * @generated from field: string rate_id = 2;
   */
  rateId = "";

  /**
   * @generated from field: agentero.policy.InsuranceType insurance_type = 3;
   */
  insuranceType = InsuranceType.UNDEFINED_INSURANCE_TYPE;

  /**
   * @generated from field: map<string, string> rate_data = 4;
   */
  rateData: { [key: string]: string } = {};

  /**
   * @generated from field: agentero.form.RaterForm rate_data_schema = 5;
   */
  rateDataSchema?: RaterForm;

  /**
   * @generated from field: string applicant = 6;
   */
  applicant = "";

  /**
   * @generated from field: agentero.rater.Address address = 7;
   */
  address?: Address;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 8;
   */
  updatedAt?: Timestamp;

  /**
   * @generated from field: bool is_editable = 9;
   */
  isEditable = false;

  constructor(data?: PartialMessage<GetApplicationDetailsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetApplicationDetailsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "results", kind: "message", T: ApplicationDetailsResult, repeated: true },
    { no: 2, name: "rate_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "insurance_type", kind: "enum", T: proto3.getEnumType(InsuranceType) },
    { no: 4, name: "rate_data", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 5, name: "rate_data_schema", kind: "message", T: RaterForm },
    { no: 6, name: "applicant", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "address", kind: "message", T: Address },
    { no: 8, name: "updated_at", kind: "message", T: Timestamp },
    { no: 9, name: "is_editable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetApplicationDetailsResponse {
    return new GetApplicationDetailsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetApplicationDetailsResponse {
    return new GetApplicationDetailsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetApplicationDetailsResponse {
    return new GetApplicationDetailsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetApplicationDetailsResponse | PlainMessage<GetApplicationDetailsResponse> | undefined, b: GetApplicationDetailsResponse | PlainMessage<GetApplicationDetailsResponse> | undefined): boolean {
    return proto3.util.equals(GetApplicationDetailsResponse, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetCarrierApplicationsStatusResponse
 */
export class GetCarrierApplicationsStatusResponse extends Message<GetCarrierApplicationsStatusResponse> {
  /**
   * @generated from field: repeated agentero.portal.GetCarrierApplicationsStatusResponse.CarrierApplication applications = 1;
   */
  applications: GetCarrierApplicationsStatusResponse_CarrierApplication[] = [];

  constructor(data?: PartialMessage<GetCarrierApplicationsStatusResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetCarrierApplicationsStatusResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "applications", kind: "message", T: GetCarrierApplicationsStatusResponse_CarrierApplication, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCarrierApplicationsStatusResponse {
    return new GetCarrierApplicationsStatusResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCarrierApplicationsStatusResponse {
    return new GetCarrierApplicationsStatusResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCarrierApplicationsStatusResponse {
    return new GetCarrierApplicationsStatusResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetCarrierApplicationsStatusResponse | PlainMessage<GetCarrierApplicationsStatusResponse> | undefined, b: GetCarrierApplicationsStatusResponse | PlainMessage<GetCarrierApplicationsStatusResponse> | undefined): boolean {
    return proto3.util.equals(GetCarrierApplicationsStatusResponse, a, b);
  }
}

/**
 * @generated from enum agentero.portal.GetCarrierApplicationsStatusResponse.ApplicationStatus
 */
export enum GetCarrierApplicationsStatusResponse_ApplicationStatus {
  /**
   * @generated from enum value: APPLICATION_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: APPLICATION_STATUS_AVAILABLE = 1;
   */
  AVAILABLE = 1,

  /**
   * @generated from enum value: APPLICATION_STATUS_NOT_AVAILABLE = 2;
   */
  NOT_AVAILABLE = 2,

  /**
   * @generated from enum value: APPLICATION_STATUS_READY_TO_QUOTE = 3;
   */
  READY_TO_QUOTE = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(GetCarrierApplicationsStatusResponse_ApplicationStatus)
proto3.util.setEnumType(GetCarrierApplicationsStatusResponse_ApplicationStatus, "agentero.portal.GetCarrierApplicationsStatusResponse.ApplicationStatus", [
  { no: 0, name: "APPLICATION_STATUS_UNSPECIFIED" },
  { no: 1, name: "APPLICATION_STATUS_AVAILABLE" },
  { no: 2, name: "APPLICATION_STATUS_NOT_AVAILABLE" },
  { no: 3, name: "APPLICATION_STATUS_READY_TO_QUOTE" },
]);

/**
 * @generated from enum agentero.portal.GetCarrierApplicationsStatusResponse.ApplicationErrorStatus
 */
export enum GetCarrierApplicationsStatusResponse_ApplicationErrorStatus {
  /**
   * @generated from enum value: APPLICATION_ERROR_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * When the carrier is APPOINTED but we have not yet entered the credentials on our side.
   *
   * @generated from enum value: APPLICATION_ERROR_STATUS_PENDING = 1;
   */
  PENDING = 1,

  /**
   * Carrier is not available for the selected address state.
   *
   * @generated from enum value: APPLICATION_ERROR_STATUS_STATE_NOT_AVAILABLE = 2;
   */
  STATE_NOT_AVAILABLE = 2,

  /**
   * Logging credentials need to be entered by an agent in order to enable this carrier.
   *
   * @generated from enum value: APPLICATION_ERROR_STATUS_MISSING_CREDENTIALS = 3;
   */
  MISSING_CREDENTIALS = 3,

  /**
   * When the agent did not fill the carrier specific questions.
   *
   * @generated from enum value: APPLICATION_ERROR_STATUS_MISSING_DATA = 4;
   */
  MISSING_DATA = 4,

  /**
   * Application has a field error.
   *
   * @generated from enum value: APPLICATION_ERROR_STATUS_FIELD_ERROR = 5;
   */
  FIELD_ERROR = 5,

  /**
   * Disabled temporarily by the carrier.
   *
   * @generated from enum value: APPLICATION_ERROR_STATUS_DISABLED_TEMPORARILY = 6;
   */
  DISABLED_TEMPORARILY = 6,

  /**
   * Coverages are not standard.
   *
   * @generated from enum value: APPLICATION_ERROR_STATUS_COVERAGES_NOT_STANDARD = 7;
   */
  COVERAGES_NOT_STANDARD = 7,

  /**
   * Additional requirements are not met.
   *
   * @generated from enum value: APPLICATION_ERROR_STATUS_CARRIER_SPECIFIC_REQUIREMENTS_NOT_MET = 8;
   */
  CARRIER_SPECIFIC_REQUIREMENTS_NOT_MET = 8,
}
// Retrieve enum metadata with: proto3.getEnumType(GetCarrierApplicationsStatusResponse_ApplicationErrorStatus)
proto3.util.setEnumType(GetCarrierApplicationsStatusResponse_ApplicationErrorStatus, "agentero.portal.GetCarrierApplicationsStatusResponse.ApplicationErrorStatus", [
  { no: 0, name: "APPLICATION_ERROR_STATUS_UNSPECIFIED" },
  { no: 1, name: "APPLICATION_ERROR_STATUS_PENDING" },
  { no: 2, name: "APPLICATION_ERROR_STATUS_STATE_NOT_AVAILABLE" },
  { no: 3, name: "APPLICATION_ERROR_STATUS_MISSING_CREDENTIALS" },
  { no: 4, name: "APPLICATION_ERROR_STATUS_MISSING_DATA" },
  { no: 5, name: "APPLICATION_ERROR_STATUS_FIELD_ERROR" },
  { no: 6, name: "APPLICATION_ERROR_STATUS_DISABLED_TEMPORARILY" },
  { no: 7, name: "APPLICATION_ERROR_STATUS_COVERAGES_NOT_STANDARD" },
  { no: 8, name: "APPLICATION_ERROR_STATUS_CARRIER_SPECIFIC_REQUIREMENTS_NOT_MET" },
]);

/**
 * @generated from message agentero.portal.GetCarrierApplicationsStatusResponse.FieldError
 */
export class GetCarrierApplicationsStatusResponse_FieldError extends Message<GetCarrierApplicationsStatusResponse_FieldError> {
  /**
   * @generated from field: string destination = 1;
   */
  destination = "";

  /**
   * @generated from field: string message = 2;
   */
  message = "";

  constructor(data?: PartialMessage<GetCarrierApplicationsStatusResponse_FieldError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetCarrierApplicationsStatusResponse.FieldError";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "destination", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCarrierApplicationsStatusResponse_FieldError {
    return new GetCarrierApplicationsStatusResponse_FieldError().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCarrierApplicationsStatusResponse_FieldError {
    return new GetCarrierApplicationsStatusResponse_FieldError().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCarrierApplicationsStatusResponse_FieldError {
    return new GetCarrierApplicationsStatusResponse_FieldError().fromJsonString(jsonString, options);
  }

  static equals(a: GetCarrierApplicationsStatusResponse_FieldError | PlainMessage<GetCarrierApplicationsStatusResponse_FieldError> | undefined, b: GetCarrierApplicationsStatusResponse_FieldError | PlainMessage<GetCarrierApplicationsStatusResponse_FieldError> | undefined): boolean {
    return proto3.util.equals(GetCarrierApplicationsStatusResponse_FieldError, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetCarrierApplicationsStatusResponse.ApplicationError
 */
export class GetCarrierApplicationsStatusResponse_ApplicationError extends Message<GetCarrierApplicationsStatusResponse_ApplicationError> {
  /**
   * @generated from field: agentero.portal.GetCarrierApplicationsStatusResponse.ApplicationErrorStatus status = 1;
   */
  status = GetCarrierApplicationsStatusResponse_ApplicationErrorStatus.UNSPECIFIED;

  /**
   * @generated from field: repeated agentero.portal.GetCarrierApplicationsStatusResponse.FieldError field_errors = 2;
   */
  fieldErrors: GetCarrierApplicationsStatusResponse_FieldError[] = [];

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  constructor(data?: PartialMessage<GetCarrierApplicationsStatusResponse_ApplicationError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetCarrierApplicationsStatusResponse.ApplicationError";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(GetCarrierApplicationsStatusResponse_ApplicationErrorStatus) },
    { no: 2, name: "field_errors", kind: "message", T: GetCarrierApplicationsStatusResponse_FieldError, repeated: true },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCarrierApplicationsStatusResponse_ApplicationError {
    return new GetCarrierApplicationsStatusResponse_ApplicationError().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCarrierApplicationsStatusResponse_ApplicationError {
    return new GetCarrierApplicationsStatusResponse_ApplicationError().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCarrierApplicationsStatusResponse_ApplicationError {
    return new GetCarrierApplicationsStatusResponse_ApplicationError().fromJsonString(jsonString, options);
  }

  static equals(a: GetCarrierApplicationsStatusResponse_ApplicationError | PlainMessage<GetCarrierApplicationsStatusResponse_ApplicationError> | undefined, b: GetCarrierApplicationsStatusResponse_ApplicationError | PlainMessage<GetCarrierApplicationsStatusResponse_ApplicationError> | undefined): boolean {
    return proto3.util.equals(GetCarrierApplicationsStatusResponse_ApplicationError, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetCarrierApplicationsStatusResponse.CarrierApplication
 */
export class GetCarrierApplicationsStatusResponse_CarrierApplication extends Message<GetCarrierApplicationsStatusResponse_CarrierApplication> {
  /**
   * @generated from field: agentero.carrier.Carrier carrier = 1;
   */
  carrier = Carrier.UNDEFINED;

  /**
   * @generated from field: agentero.portal.GetCarrierApplicationsStatusResponse.ApplicationStatus status = 2;
   */
  status = GetCarrierApplicationsStatusResponse_ApplicationStatus.UNSPECIFIED;

  /**
   * @generated from field: agentero.portal.GetCarrierApplicationsStatusResponse.ApplicationError error = 3;
   */
  error?: GetCarrierApplicationsStatusResponse_ApplicationError;

  /**
   * @generated from field: bool is_binding_with_agentero = 4;
   */
  isBindingWithAgentero = false;

  /**
   * @generated from field: repeated agentero.policy.InsuranceType insurance_types = 5;
   */
  insuranceTypes: InsuranceType[] = [];

  /**
   * @generated from field: bool use_own_code = 6;
   */
  useOwnCode = false;

  constructor(data?: PartialMessage<GetCarrierApplicationsStatusResponse_CarrierApplication>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetCarrierApplicationsStatusResponse.CarrierApplication";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "carrier", kind: "enum", T: proto3.getEnumType(Carrier) },
    { no: 2, name: "status", kind: "enum", T: proto3.getEnumType(GetCarrierApplicationsStatusResponse_ApplicationStatus) },
    { no: 3, name: "error", kind: "message", T: GetCarrierApplicationsStatusResponse_ApplicationError },
    { no: 4, name: "is_binding_with_agentero", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "insurance_types", kind: "enum", T: proto3.getEnumType(InsuranceType), repeated: true },
    { no: 6, name: "use_own_code", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCarrierApplicationsStatusResponse_CarrierApplication {
    return new GetCarrierApplicationsStatusResponse_CarrierApplication().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCarrierApplicationsStatusResponse_CarrierApplication {
    return new GetCarrierApplicationsStatusResponse_CarrierApplication().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCarrierApplicationsStatusResponse_CarrierApplication {
    return new GetCarrierApplicationsStatusResponse_CarrierApplication().fromJsonString(jsonString, options);
  }

  static equals(a: GetCarrierApplicationsStatusResponse_CarrierApplication | PlainMessage<GetCarrierApplicationsStatusResponse_CarrierApplication> | undefined, b: GetCarrierApplicationsStatusResponse_CarrierApplication | PlainMessage<GetCarrierApplicationsStatusResponse_CarrierApplication> | undefined): boolean {
    return proto3.util.equals(GetCarrierApplicationsStatusResponse_CarrierApplication, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetRateStatisticsRequest
 */
export class GetRateStatisticsRequest extends Message<GetRateStatisticsRequest> {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId = "";

  /**
   * @generated from field: agentero.carrier.Carrier carrier = 2;
   */
  carrier = Carrier.UNDEFINED;

  constructor(data?: PartialMessage<GetRateStatisticsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetRateStatisticsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "carrier", kind: "enum", T: proto3.getEnumType(Carrier) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRateStatisticsRequest {
    return new GetRateStatisticsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRateStatisticsRequest {
    return new GetRateStatisticsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRateStatisticsRequest {
    return new GetRateStatisticsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetRateStatisticsRequest | PlainMessage<GetRateStatisticsRequest> | undefined, b: GetRateStatisticsRequest | PlainMessage<GetRateStatisticsRequest> | undefined): boolean {
    return proto3.util.equals(GetRateStatisticsRequest, a, b);
  }
}

/**
 * @generated from message agentero.portal.GetRateStatisticsResponse
 */
export class GetRateStatisticsResponse extends Message<GetRateStatisticsResponse> {
  /**
   * @generated from field: int64 total_rates = 1;
   */
  totalRates = protoInt64.zero;

  /**
   * @generated from field: int64 total_rates_by_carrier = 2;
   */
  totalRatesByCarrier = protoInt64.zero;

  /**
   * @generated from field: int64 total_quotes_ok_by_carrier = 3;
   */
  totalQuotesOkByCarrier = protoInt64.zero;

  /**
   * @generated from field: int64 total_bind_requests_by_carrier = 4;
   */
  totalBindRequestsByCarrier = protoInt64.zero;

  constructor(data?: PartialMessage<GetRateStatisticsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "agentero.portal.GetRateStatisticsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "total_rates", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "total_rates_by_carrier", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "total_quotes_ok_by_carrier", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "total_bind_requests_by_carrier", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRateStatisticsResponse {
    return new GetRateStatisticsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRateStatisticsResponse {
    return new GetRateStatisticsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRateStatisticsResponse {
    return new GetRateStatisticsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetRateStatisticsResponse | PlainMessage<GetRateStatisticsResponse> | undefined, b: GetRateStatisticsResponse | PlainMessage<GetRateStatisticsResponse> | undefined): boolean {
    return proto3.util.equals(GetRateStatisticsResponse, a, b);
  }
}

