'use client';

import { ReactNode } from 'react';

import {
	Content,
	Portal,
	Provider,
	TooltipProps as RadixTooltipProps,
	Root,
	Trigger
} from '@radix-ui/react-tooltip';

import { styled } from '@agentero/styles/jsx';

type TooltipProps = {
	children: ReactNode;
	content: ReactNode;
} & RadixTooltipProps;

const TooltipTrigger = styled(Trigger, {
	base: {
		verticalAlign: 'middle'
	}
});

const TooltipContent = styled(Content, {
	base: {
		zIndex: 999999,
		maxWidth: '24ch',
		paddingBlock: '8',
		paddingInline: '12',
		textStyle: 'caption.base',
		color: 'text.default.base.secondary',
		border: '0.0625rem solid token(colors.border.default.base.primary)',
		background: 'background.default.base.primary',
		userSelect: 'none',
		borderRadius: 'sm',
		boxShadow: 'sm',
		animationDuration: '.4s',
		opacity: 1,
		transform: 'translateY(0) translateX(0)',
		animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
		'&[data-state="delayed-open"]': {
			'&[data-side="top"]': {
				animationName: 'slideUpAndFade'
			},
			'&[data-side="right"]': {
				animationName: 'slideRightAndFade'
			},
			'&[data-side="bottom"]': {
				animationName: 'slideDownAndFade'
			},
			'&[data-side="left"]': {
				animationName: 'slideLeftAndFade'
			}
		}
	}
});

export const Tooltip = ({ children, content, onOpenChange }: TooltipProps) => {
	return (
		<>
			<Provider delayDuration={0} skipDelayDuration={0}>
				<Root onOpenChange={onOpenChange}>
					<TooltipTrigger asChild>
						<button type="button" data-testid="tooltip-trigger">
							{children}
						</button>
					</TooltipTrigger>
					<Portal>
						<TooltipContent sideOffset={4} role="tooltip">
							{content}
						</TooltipContent>
					</Portal>
				</Root>
			</Provider>
		</>
	);
};
