import {
	AnchorHTMLAttributes,
	ButtonHTMLAttributes,
	Children,
	ForwardedRef,
	PropsWithChildren,
	forwardRef,
	isValidElement
} from 'react';

import Link, { LinkProps } from 'next/link';

import { cva } from '@agentero/styles/css';
import { styled } from '@agentero/styles/jsx';
import { RecipeVariantProps } from '@agentero/styles/types';

export const buttonStyle = cva({
	base: {
		position: 'relative',
		display: 'inline-flex',
		alignItems: 'center',
		fontWeight: '600',
		borderRadius: 'md',
		cursor: 'pointer',
		backgroundColor: 'transparent',
		border: '0.0625rem solid transparent',
		textDecoration: 'none',
		whiteSpace: 'nowrap',
		transition: 'background 0.2s, border-color 0.2s, color 0.2s',
		WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
		_disabled: {
			cursor: 'not-allowed',
			pointerEvents: 'none'
		},
		_focusVisible: {
			outlineColor: 'focusRing.button.primary',
			outlineOffset: '0.125rem',
			outlineStyle: 'solid',
			outlineWidth: '0.125rem'
		}
	},
	variants: {
		variant: {
			primary: {
				backgroundColor: 'background.button.primaryEnable',
				color: 'text.button.primaryEnable',
				fill: 'icon.button.primaryEnable',
				borderColor: 'background.button.primaryEnable',
				'& svg path': {
					fill: 'icon.button.primaryEnable'
				},
				_hover: {
					backgroundColor: 'background.button.primaryHover',
					borderColor: 'background.button.primaryHover'
				},
				_disabled: {
					backgroundColor: 'background.button.primaryDisable',
					color: 'text.button.primaryDisable',
					fill: 'icon.button.primaryDisable',
					borderColor: 'background.button.primaryDisable',
					'& svg path': {
						fill: 'icon.button.primaryDisable'
					}
				}
			},
			secondary: {
				backgroundColor: 'background.button.secondaryEnable',
				color: 'text.button.secondaryEnable',
				fill: 'icon.button.secondaryEnable',
				borderColor: 'border.button.secondaryEnable',
				boxShadow: '1',
				'& svg path': {
					fill: 'icon.button.secondaryEnable'
				},
				_hover: {
					backgroundColor: 'background.button.secondaryHover',
					borderColor: 'border.button.secondaryHover'
				},
				_disabled: {
					backgroundColor: 'background.button.secondaryDisable',
					color: 'text.button.secondaryDisable',
					fill: 'icon.button.secondaryDisable',
					borderColor: 'border.button.secondaryDisable',
					'& svg path': {
						fill: 'icon.button.secondaryDisable'
					}
				}
			},
			tertiary: {
				backgroundColor: 'background.button.tertiaryEnable',
				color: 'text.button.tertiaryEnable',
				fill: 'icon.button.tertiaryEnable',
				borderColor: 'background.button.tertiaryEnable',
				'& svg path': {
					fill: 'icon.button.tertiaryEnable'
				},
				_hover: {
					backgroundColor: 'background.button.tertiaryHover',
					borderColor: 'background.button.tertiaryHover'
				},
				_disabled: {
					backgroundColor: 'background.button.tertiaryDisable',
					color: 'text.button.tertiaryDisable',
					fill: 'icon.button.tertiaryDisable',
					borderColor: 'background.button.tertiaryDisable',
					'& svg path': {
						fill: 'icon.button.tertiaryDisable'
					}
				}
			},
			ghost: {
				backgroundColor: 'background.button.ghostEnable',
				color: 'text.button.ghostEnable',
				fill: 'icon.button.ghostEnable',
				borderColor: 'background.button.ghostEnable',
				mixBlendMode: 'multiply',
				backgroundBlendMode: 'multiply',
				'& svg path': {
					fill: 'icon.button.ghostEnable'
				},
				_hover: {
					backgroundColor: 'background.button.ghostHover',
					borderColor: 'background.button.ghostHover'
				},
				_disabled: {
					backgroundColor: 'background.button.ghostDisable',
					color: 'text.button.ghostDisable',
					fill: 'icon.button.ghostDisable',
					borderColor: 'background.button.ghostDisable',
					'& svg path': {
						fill: 'icon.button.ghostDisable'
					}
				}
			},
			link: {
				paddingInline: '0',
				textDecoration: 'underline',
				textUnderlineOffset: 'var(--text-underline-offset)',
				color: 'text.button.linkEnable',
				fill: 'icon.button.linkEnable',
				'& svg path': {
					fill: 'icon.button.linkEnable'
				},
				_disabled: {
					color: 'text.button.linkDisable',
					fill: 'icon.button.linkDisable',
					'& svg path': {
						fill: 'icon.button.linkDisable'
					}
				}
			}
		},
		size: {
			xs: {
				gap: '8',
				textStyle: 'caption.base',
				height: '1.5rem',
				minWidth: '4rem',
				paddingInline: 'calc(token(spacing.12) - token(spacing.1))',
				'& svg': {
					width: '1.25rem',
					height: '1.25rem',
					marginInline: '-4'
				}
			},
			sm: {
				gap: '8',
				textStyle: 'body.small',
				height: '2rem',
				minWidth: '5.375rem',
				paddingInline: 'calc(token(spacing.12) - token(spacing.1))',
				'& svg': {
					width: '1.25rem',
					height: '1.25rem',
					marginInline: '-4'
				}
			},
			md: {
				gap: '16',
				textStyle: 'body.small',
				height: '2.5rem',
				paddingInline: 'calc(token(spacing.20) - token(spacing.1))',
				minWidth: '6.25rem',
				'& svg': {
					width: '1.5rem',
					height: '1.5rem',
					marginInline: '-8'
				}
			},
			lg: {
				gap: '16',
				textStyle: 'body',
				height: '3rem',
				minWidth: '7.5rem',
				paddingInline: 'calc(token(spacing.20) - token(spacing.1))',
				borderRadius: 'lg',
				'& svg': {
					width: '1.5rem',
					height: '1.5rem',
					marginInline: '-8'
				}
			}
		},
		status: {
			danger: {
				_focusVisible: {
					outlineColor: 'focusRing.button.destructive'
				}
			}
		},
		hasOnlyIcon: {
			true: {
				aspectRatio: 1,
				paddingInline: '0',
				minWidth: 'unset'
			}
		},
		disabled: {
			true: {
				cursor: 'not-allowed',
				pointerEvents: 'none'
			}
		},
		rounded: {
			true: {
				borderRadius: 'full'
			}
		},
		align: {
			center: {
				justifyContent: 'center'
			},
			start: {
				justifyContent: 'flex-start'
			},
			end: {
				justifyContent: 'flex-end'
			},
			justify: {
				justifyContent: 'space-between'
			}
		},
		fitContent: {
			true: {
				minWidth: 'fit-content'
			}
		}
	},
	compoundVariants: [
		{
			variant: 'primary',
			status: 'danger',
			css: {
				color: 'text.button.primaryDestructiveEnable',
				backgroundColor: 'background.button.primaryDestructiveEnable',
				borderColor: 'background.button.primaryDestructiveEnable',
				fill: 'icon.button.primaryDestructiveEnable',
				'& svg path': {
					fill: 'icon.button.primaryDestructiveEnable'
				},
				_hover: {
					backgroundColor: 'background.button.primaryDestructiveHover',
					borderColor: 'background.button.primaryDestructiveHover'
				},
				_disabled: {
					backgroundColor: 'background.button.primaryDestructiveDisable',
					color: 'text.button.primaryDestructiveDisable',
					fill: 'icon.button.primaryDestructiveDisable',
					borderColor: 'background.button.primaryDestructiveDisable',
					'& svg path': {
						fill: 'icon.button.primaryDestructiveDisable'
					}
				}
			}
		},
		{
			variant: 'secondary',
			status: 'danger',
			css: {
				color: 'text.button.secondaryDestructiveEnable',
				backgroundColor: 'background.button.secondaryDestructiveEnable',
				borderColor: 'border.button.secondaryDestructiveEnable',
				fill: 'icon.button.secondaryDestructiveEnable',
				'& svg path': {
					fill: 'icon.button.secondaryDestructiveEnable'
				},
				_hover: {
					backgroundColor: 'background.button.secondaryDestructiveHover',
					borderColor: 'border.button.secondaryDestructiveHover'
				},
				_disabled: {
					backgroundColor: 'background.button.secondaryDestructiveDisable',
					color: 'text.button.secondaryDestructiveDisable',
					fill: 'icon.button.secondaryDestructiveDisable',
					borderColor: 'border.button.secondaryDestructiveDisable',
					'& svg path': {
						fill: 'icon.button.secondaryDestructiveDisable'
					}
				}
			}
		},
		{
			variant: 'tertiary',
			status: 'danger',
			css: {
				color: 'text.button.tertiaryDestructiveEnable',
				backgroundColor: 'background.button.tertiaryDestructiveEnable',
				borderColor: 'background.button.tertiaryDestructiveEnable',
				fill: 'icon.button.tertiaryDestructiveEnable',
				'& svg path': {
					fill: 'icon.button.tertiaryDestructiveEnable'
				},
				_hover: {
					backgroundColor: 'background.button.tertiaryDestructiveHover',
					borderColor: 'background.button.tertiaryDestructiveHover'
				},
				_disabled: {
					backgroundColor: 'background.button.tertiaryDestructiveDisable',
					color: 'text.button.tertiaryDestructiveDisable',
					fill: 'icon.button.tertiaryDestructiveDisable',
					borderColor: 'background.button.tertiaryDestructiveDisable',
					'& svg path': {
						fill: 'icon.button.tertiaryDestructiveDisable'
					}
				}
			}
		},
		{
			variant: 'ghost',
			status: 'danger',
			css: {
				color: 'text.button.ghostDestructiveEnable',
				backgroundColor: 'background.button.ghostDestructiveEnable',
				borderColor: 'background.button.ghostDestructiveEnable',
				fill: 'icon.button.ghostDestructiveEnable',
				'& svg path': {
					fill: 'icon.button.ghostDestructiveEnable'
				},
				_hover: {
					backgroundColor: 'background.button.ghostDestructiveHover',
					borderColor: 'background.button.ghostDestructiveHover'
				},
				_disabled: {
					backgroundColor: 'background.button.ghostDestructiveDisable',
					color: 'text.button.ghostDestructiveDisable',
					fill: 'icon.button.ghostDestructiveDisable',
					borderColor: 'background.button.ghostDestructiveDisable',
					'& svg path': {
						fill: 'icon.button.ghostDestructiveDisable'
					}
				}
			}
		},
		{
			variant: 'link',
			status: 'danger',
			css: {
				color: 'text.button.linkDestructiveEnable',
				fill: 'icon.button.linkDestructiveEnable',
				'& svg path': {
					fill: 'icon.button.linkDestructiveEnable'
				},
				_hover: {
					color: 'text.button.linkDestructiveHover',
					fill: 'icon.button.linkDestructiveHover',
					'& svg path': {
						fill: 'icon.button.linkDestructiveHover'
					}
				},
				_disabled: {
					color: 'text.button.linkDestructiveDisable',
					fill: 'icon.button.linkDestructiveDisable',
					'& svg path': {
						fill: 'icon.button.linkDestructiveDisable'
					}
				}
			}
		},
		{
			disabled: true,
			variant: 'primary',
			css: {
				backgroundColor: 'background.button.primaryDisable',
				color: 'text.button.primaryDisable',
				fill: 'icon.button.primaryDisable',
				borderColor: 'background.button.primaryDisable',
				'& svg path': {
					fill: 'icon.button.primaryDisable'
				}
			}
		},
		{
			disabled: true,
			variant: 'secondary',
			css: {
				backgroundColor: 'background.button.secondaryDisable',
				color: 'text.button.secondaryDisable',
				fill: 'icon.button.secondaryDisable',
				borderColor: 'border.button.secondaryDisable',
				'& svg path': {
					fill: 'icon.button.secondaryDisable'
				}
			}
		},
		{
			disabled: true,
			variant: 'tertiary',
			css: {
				backgroundColor: 'background.button.tertiaryDisable',
				color: 'text.button.tertiaryDisable',
				fill: 'icon.button.tertiaryDisable',
				borderColor: 'background.button.tertiaryDisable',
				'& svg path': {
					fill: 'icon.button.tertiaryDisable'
				}
			}
		},
		{
			disabled: true,
			variant: 'ghost',
			css: {
				backgroundColor: 'background.button.ghostDisable',
				color: 'text.button.ghostDisable',
				fill: 'icon.button.ghostDisable',
				borderColor: 'background.button.ghostDisable',
				'& svg path': {
					fill: 'icon.button.ghostDisable'
				}
			}
		},
		{
			disabled: true,
			variant: 'link',
			css: {
				color: 'text.button.linkDisable',
				fill: 'icon.button.linkDisable',
				'& svg path': {
					fill: 'icon.button.linkDisable'
				}
			}
		},
		{
			disabled: true,
			variant: 'primary',
			status: 'danger',
			css: {
				backgroundColor: 'background.button.primaryDestructiveDisable',
				color: 'text.button.primaryDestructiveDisable',
				fill: 'icon.button.primaryDestructiveDisable',
				borderColor: 'background.button.primaryDestructiveDisable',
				'& svg path': {
					fill: 'icon.button.primaryDestructiveDisable'
				}
			}
		},
		{
			disabled: true,
			variant: 'secondary',
			status: 'danger',
			css: {
				backgroundColor: 'background.button.secondaryDestructiveDisable',
				color: 'text.button.secondaryDestructiveDisable',
				fill: 'icon.button.secondaryDestructiveDisable',
				borderColor: 'border.button.secondaryDestructiveDisable',
				'& svg path': {
					fill: 'icon.button.secondaryDestructiveDisable'
				}
			}
		},
		{
			disabled: true,
			variant: 'tertiary',
			status: 'danger',
			css: {
				backgroundColor: 'background.button.tertiaryDestructiveDisable',
				color: 'text.button.tertiaryDestructiveDisable',
				fill: 'icon.button.tertiaryDestructiveDisable',
				borderColor: 'background.button.tertiaryDestructiveDisable',
				'& svg path': {
					fill: 'icon.button.tertiaryDestructiveDisable'
				}
			}
		},
		{
			disabled: true,
			variant: 'ghost',
			status: 'danger',
			css: {
				backgroundColor: 'background.button.ghostDestructiveDisable',
				color: 'text.button.ghostDestructiveDisable',
				fill: 'icon.button.ghostDestructiveDisable',
				borderColor: 'background.button.ghostDestructiveDisable',
				'& svg path': {
					fill: 'icon.button.ghostDestructiveDisable'
				}
			}
		},
		{
			disabled: true,
			variant: 'link',
			status: 'danger',
			css: {
				color: 'text.button.linkDestructiveDisable',
				fill: 'icon.button.linkDestructiveDisable',
				'& svg path': {
					fill: 'icon.button.linkDestructiveDisable'
				}
			}
		},
		{
			variant: 'link',
			size: ['xs', 'sm', 'md', 'lg'],
			css: {
				gap: '4',
				paddingBlock: '0',
				paddingInline: '0',
				height: 'auto',
				minWidth: 'unset',
				'& svg': {
					marginInline: '0'
				}
			}
		},
		{
			hasOnlyIcon: true,
			size: 'lg',
			css: {
				borderRadius: 'md',
				'& svg': {
					width: '2rem',
					height: '2rem'
				}
			}
		}
	],
	defaultVariants: {
		variant: 'primary',
		size: 'sm',
		align: 'center'
	}
});

export type ButtonVariantType = NonNullable<RecipeVariantProps<typeof buttonStyle>>['variant'];

export type ButtonSizeType = NonNullable<RecipeVariantProps<typeof buttonStyle>>['size'];

type ButtonBaseProps = Omit<
	{
		as?: 'a' | 'link' | 'button';
		loading?: boolean;
		disabled?: boolean;
	} & RecipeVariantProps<typeof buttonStyle>,
	'hasOnlyIcon'
>;

export type ButtonAsAnchor = ButtonBaseProps &
	AnchorHTMLAttributes<HTMLAnchorElement> & { as?: 'a' };
export type ButtonAsButton = ButtonBaseProps &
	ButtonHTMLAttributes<HTMLButtonElement> & { as?: 'button' };
export type ButtonAsLink = ButtonBaseProps &
	LinkProps &
	AnchorHTMLAttributes<HTMLAnchorElement> & { as?: 'link' };

export type ButtonProps = ButtonAsButton | ButtonAsAnchor | ButtonAsLink;

const ButtonLoading = styled('div', {
	base: {
		position: 'absolute',
		top: '0',
		left: '0',
		right: '0',
		bottom: '0',
		display: 'grid',
		placeItems: 'center',
		borderRadius: 'inherit',
		backgroundColor: 'inherit',
		opacity: '0',
		animation: 'fadeIn .3s forwards',
		'&:before': {
			content: '""',
			display: 'block',
			height: '1em',
			width: '1em',
			borderWidth: '0.125rem',
			borderStyle: 'solid',
			borderRadius: 'full',
			borderColor: 'inherit',
			borderTopColor: 'transparent',
			animation: 'spinAround .5s infinite linear'
		}
	}
});

export const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
	(
		{
			children,
			as = 'button',
			variant,
			size = 'sm',
			loading,
			disabled,
			rounded,
			status,
			align,
			fitContent,
			...props
		}: PropsWithChildren<ButtonProps>,
		ref
	) => {
		const hasOnlyIcon = Children.toArray(children).every(value => isValidElement(value));
		const isDisabled = disabled || loading;
		const className = buttonStyle({
			variant,
			size,
			hasOnlyIcon,
			disabled: isDisabled,
			status,
			rounded,
			align,
			fitContent
		});

		if (loading && variant === 'link') {
			throw new Error('Button with variant link and loading is not supported');
		}

		if (hasOnlyIcon && variant === 'link') {
			throw new Error(
				'Button with no text and variant link is not supported. Use it with variant tertiary instead'
			);
		}

		const content = (
			<>
				{loading && <ButtonLoading aria-label="loading" role="status" />}
				{children}
			</>
		);

		if (as === 'a') {
			return (
				<a
					className={className}
					{...(props as ButtonAsAnchor)}
					ref={ref as ForwardedRef<HTMLAnchorElement>}>
					{content}
				</a>
			);
		}

		if (as === 'link') {
			return (
				<Link
					className={className}
					{...(props as ButtonAsLink)}
					ref={ref as ForwardedRef<HTMLAnchorElement>}>
					{content}
				</Link>
			);
		}

		return (
			<button
				className={className}
				{...(props as ButtonAsButton)}
				disabled={isDisabled}
				ref={ref as ForwardedRef<HTMLButtonElement>}>
				{content}
			</button>
		);
	}
);
