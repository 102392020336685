import {
	AnchorHTMLAttributes,
	ButtonHTMLAttributes,
	DetailedHTMLProps,
	HTMLAttributes,
	PropsWithChildren,
	Ref,
	forwardRef
} from 'react';

import { cva, cx } from '@agentero/styles/css';
import { RecipeVariantProps } from '@agentero/styles/types';

const cardRecipe = cva({
	base: {
		'--paddingInline': 'token(spacing.32)',
		'--paddingBlock': 'token(spacing.32)',
		'--border-radius-start': 'token(radii.md)',
		'--border-radius-end': 'token(radii.md)',
		'--border-width': '0.0625rem',
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		paddingInline: 'var(--paddingInline)',
		paddingBlock: 'var(--paddingBlock)',
		borderRadius:
			'var(--border-radius-start) var(--border-radius-end) var(--border-radius-end) var(--border-radius-start)',
		borderWidth: 'var(--border-width)',
		borderStyle: 'solid',
		borderColor: 'border.default.base.primary',
		boxShadow: '1'
	},
	variants: {
		highlighted: {
			true: {
				boxShadow: 'inset 0 0 0 2px token(colors.border)'
			}
		},
		size: {
			sm: {
				'--paddingBlock': 'token(spacing.16)',
				'--paddingInline': 'token(spacing.16)'
			},
			md: {
				'--paddingBlock': 'token(spacing.24)',
				'--paddingInline': 'token(spacing.24)'
			},
			lg: {
				'--paddingBlock': 'token(spacing.32)',
				'--paddingInline': 'token(spacing.32)'
			}
		},
		asCover: {
			true: {
				'--width': 'token(spacing.8)',
				'--border-radius-start': '0',
				'--border-radius-end': 'token(radii.md)',
				position: 'relative',
				marginInlineEnd: 'var(--width)',
				_before: {
					content: '""',
					position: 'absolute',
					top: '4',
					right: 'calc(-1 * var(--width))',
					bottom: '4',
					width: 'var(--width)',
					backgroundColor: 'bg',
					border: 'inherit',
					borderRadius: 'md',
					borderTopLeftRadius: 0,
					borderBottomLeftRadius: 0,
					boxShadow: '1'
				}
			}
		}
	},
	defaultVariants: {
		size: 'lg'
	}
});

type CardProps = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> &
	RecipeVariantProps<typeof cardRecipe>;

export const Card = forwardRef<HTMLDivElement, CardProps>(
	(
		{ children, className, highlighted, size, asCover, ...props }: PropsWithChildren<CardProps>,
		ref
	) => {
		const styles = cx(cardRecipe({ highlighted, size, asCover }), className);

		return (
			<article className={styles} ref={ref} {...props}>
				{children}
			</article>
		);
	}
);

const cardActionAreaRecipe = cva({
	base: {
		marginBlock: 'calc(-1 * var(--paddingBlock))',
		marginInline: 'calc(-1 * var(--paddingInline))',
		paddingInline: 'var(--paddingInline)',
		paddingBlock: 'var(--paddingBlock)',
		height: 'calc(100% + var(--paddingBlock) * 2)',
		cursor: 'pointer',
		textAlign: 'unset',
		textDecoration: 'none',
		transition: 'background 0.2s',
		borderRadius:
			'var(--border-radius-start) calc(var(--border-radius-end) - var(--border-width)) calc(var(--border-radius-end) - var(--border-width)) var(--border-radius-start)',
		_hover: {
			backgroundColor: 'background.button.ghostHover'
		}
	}
});

type CardActionAreaVariants = RecipeVariantProps<typeof cardActionAreaRecipe>;

type CardActionAreaBaseProps = {
	as?: 'button' | 'a';
	className?: string;
} & CardActionAreaVariants;

type CardActionAreaAnchor = CardActionAreaBaseProps &
	AnchorHTMLAttributes<HTMLAnchorElement> & { as?: 'a' };
type CardActionAreaButton = CardActionAreaBaseProps &
	ButtonHTMLAttributes<HTMLButtonElement> & { as?: 'button' };

export type CardActionAreaProps = CardActionAreaAnchor | CardActionAreaButton;

export const CardActionArea = forwardRef<
	HTMLButtonElement | HTMLAnchorElement,
	CardActionAreaProps
>(
	(
		{ as = 'button', children, className, ...props }: PropsWithChildren<CardActionAreaProps>,
		ref
	) => {
		const styles = cx(cardActionAreaRecipe({}), className);

		if (as === 'a') {
			return (
				<a
					{...(props as CardActionAreaAnchor)}
					className={styles}
					ref={ref as Ref<HTMLAnchorElement>}>
					{children}
				</a>
			);
		}

		return (
			<button
				{...(props as CardActionAreaButton)}
				className={styles}
				ref={ref as Ref<HTMLButtonElement>}>
				{children}
			</button>
		);
	}
);
