'use client';

// Panda - Styling JSX Compound Components: https://panda-css.com/docs/concepts/slot-recipes#styling-jsx-compound-components
// Based on the TypeScript version in Park UI: https://github.com/cschroeter/park-ui/blob/main/website/src/lib/create-style-context.tsx
import {
	type ComponentProps,
	type ElementType,
	type ReactElement,
	createContext,
	createElement,
	forwardRef,
	useContext
} from 'react';

import { cx } from '../styled-system/css';

type GenericProps = Record<string, unknown>;
type StyleRecipe = {
	(props?: GenericProps): Record<string, string>;
	splitVariantProps: (props: GenericProps) => any;
};
type StyleSlot<R extends StyleRecipe> = keyof ReturnType<R>;
type StyleSlotRecipe<R extends StyleRecipe> = Record<StyleSlot<R>, string>;
type StyleVariantProps<R extends StyleRecipe> = Parameters<R>[0];
type CombineProps<T, U> = Omit<T, keyof U> & U;

export interface ComponentVariants<T extends ElementType, R extends StyleRecipe> {
	(props: CombineProps<ComponentProps<T>, StyleVariantProps<R>>): ReactElement;
}

export const createStyleContext = <R extends StyleRecipe>(recipe: R) => {
	const StyleContext = createContext<StyleSlotRecipe<R> | null>(null);

	const withProvider = <T extends ElementType>(
		Component: T,
		slot?: StyleSlot<R>
	): ComponentVariants<T, R> => {
		const StyledComponent = forwardRef<unknown, ComponentProps<T>>((props, ref) => {
			const [variantProps, otherProps] = recipe.splitVariantProps(props);
			const slotStyles = recipe(variantProps) as StyleSlotRecipe<R>;
			return (
				<StyleContext.Provider value={slotStyles}>
					<Component
						ref={ref}
						{...otherProps}
						className={cx(slotStyles[slot ?? ''], otherProps.className)}
					/>
				</StyleContext.Provider>
			);
		});
		return StyledComponent as unknown as ComponentVariants<T, R>;
	};

	const withContext = <T extends ElementType>(Component: T, slot?: StyleSlot<R>): T => {
		if (!slot) return Component;
		const StyledComponent = forwardRef<unknown, ComponentProps<T>>((props, ref) => {
			const slotStyles = useContext(StyleContext);
			return createElement(Component, {
				...props,
				className: cx(slotStyles?.[slot ?? ''], props.className),
				ref
			});
		});
		return StyledComponent as unknown as T;
	};

	return {
		withProvider,
		withContext
	};
};
